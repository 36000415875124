import React from 'react'
import styled from 'styled-components'

import { Input, Textarea } from './common/form'
import Button from './Button'

const SubmitButton = styled(Button)`
  margin-top: 0.5rem;
`

const Form = ({ className }) => (
  <form
    className={`${className} form`}
    name="contactUs"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="contactUs" />
    <Input name="firstName" label="First Name" />
    <Input name="lastName" label="Last Name" />
    <Input name="email" label="Email" type="email" />
    <Textarea name="message" label="Message" />

    <div data-netlify-recaptcha="true" />
    <SubmitButton primary submit>
      Submit
    </SubmitButton>
  </form>
)

export default styled(Form)`
  margin: 1.5rem 0;
`
