import React from 'react'
import styled from 'styled-components'
import { vars } from '../components/theme'
import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import ContactUsForm from '../components/ContactUsForm'
import MetaHeader from '../components/MetaHeader'

import nyc from '../images/backgrounds/nyc.png'
import yyz from '../images/backgrounds/yyz.png'
import yvr from '../images/backgrounds/yvr.png'

const Wrapper = styled.div`
  margin: 4rem 0;
`
const LocationContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -0.75rem;
`
const Location = styled(({ className, img, title, children }) => (
  <div className={className}>
    {img && <img src={img} alt={title} />}
    <h4>{title}</h4>
    {children}
  </div>
))`
  flex: 1 0 100%;
  padding: 0 0.75rem;

  @media (min-width: ${vars.screenSmall}) {
    flex: 0 0 30%;
    flex-flow: row nowrap;
  }

  img {
    width: 100%;
  }
`

const ContactPage = ({ data }) => (
  <Layout>
    <MetaHeader title="Contact Us" />

    <Wrapper>
      <PageWrapper small>
        <h1>Contact Us</h1>

        <p>
          Want to know more about Angel Academy, corporate partnership, or
          investor mentorship? Drop us a line here and someone from our team
          will get back to you.
        </p>

        <ContactUsForm />
      </PageWrapper>

      <PageWrapper>
        <section>
          <LocationContainer>
            <Location img={yvr} title="Vancouver">
              <p>
                555 Burrard St, 2nd floor
                <br />
                Vancouver, BC <br />
                V7X 1M8
              </p>
            </Location>
            <Location img={yyz} title="Toronto">
              <p>
                1 University Ave, Suite 300
                <br />
                Toronto, ON
                <br />
                M5J 2P1
              </p>
            </Location>
            <Location img={nyc} title="New York City">
              <p>
                1 Little West 12th St
                <br />
                New York, NY
                <br />
                10014
              </p>
            </Location>
          </LocationContainer>
        </section>
      </PageWrapper>
    </Wrapper>
  </Layout>
)

export default ContactPage
